const BaseURL = 'https://efut-crm.melian.me/api';

const titles = {
  siteName: 'EFUT CRM',
}

const logos = {
  logoTopbar: 'efut_logo_white.svg',
  logoLogin: 'footer-logo-efuturo.png',
  backgroundLogin: 'background.jpg',
}
export { 
  BaseURL,
  titles,
  logos
}
