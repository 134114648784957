import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

body .ant-form-item-label {
  text-align: left;
}
body .ant-form-item-control-input-content textarea.ant-input {
  min-height: 100px;
  resize: none;
}

.text-right {
  text-align: right; }
  @media (max-width: 575px) {
    .text-right {
      text-align: center; 
  }
}
.texto-center {
  text-align: center;
}

/*form image*/
.form-item-image {
  height: 150px;
  width: auto;
}

/*-------MELIAN CRM------------------------------*/
.campaign-data-section p {
  font-size: 14px;
}
/*-----------------------------------------------*/

/*ck-editor styles*/
div.ck-editor__editable {
  min-height: 150px;
}

.ck-content p {
  margin-bottom: 15px;
}

.profile_picture {
  width: 60px;
  height: 60px; 
}

.isoTableSearchBox {
  display: flex;
  margin-bottom: 15px;
}
.minus-circle-custom.anticon svg {
  width: 14px;
  height: 14px;
}

.content-image-icon
{
  //text-align:left;
  .content-image-icon-background
  {
    background-color:#dfdfdf;
    padding:15px 15px;
    display:inline-block;
    img{
      max-width:100%;
      height:auto;
      min-height:45px;
      max-height:130px;
    }
  }
}

.content-image-background
{
  //text-align:left;
  .content-image-background-background
  {
    display:inline-block;
    background-color:#fafafa;
    padding:15px 10px;
    max-width:70%;
    img{
      max-width:100%;
      height:auto;
    }
    @media (max-width: 767px) 
    {
      max-width:100%;
    }
  }
}
.progress-table td a span svg{
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -71%);
}
.image-menu{
  .ant-form-item-control-input-content{
    background-color:#0D081E;
    text-align:center;
  }
}  

.no-display.undefined{
  display:none;
}

.display.undefined{
  display:block;
}
.divider-custom.divider-custom{
  border-left-color: #cccccc;
  margin-right: 20px;
  margin-left: 20px;
  height: 1em;
}
.ant-btn.ant-btn-primary.wrap-button{
  white-space: break-spaces;
  height: unset;
  min-height: 36px;
}
.row-space-between{
  display: flex;
  justify-content:space-between;
}
//css vista información usuario
  .title-section{
    background: #ededed;
    padding: 2px 8px;
    margin-bottom: 10px;
    border: 1px solid #d5cdcd;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
li{
  font-size: 14px;
}
  .content-section-campaign{
    font-size: 14px;
  }
  .subtitle-section{
    background: #ededed;
    padding: 4px 8px;
    margin-bottom: 5px;
    margin-top: 5px !important;
    border: 1px solid #d5cdcd;
    font-weight: 700;
  }
  .style-column-data{
    border: 1px solid #d5cdcd;
    
  }
  .title-section-table{
    background: #ededed;
    padding: 2px 8px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #d5cdcd;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
  .tag-section{
    margin-bottom: 20px;
    
  }
  .tag-section .ant-tag{
    margin-left: 10px;
  }
  .hidden{
    display: none !important;
  }
  .line-table{
    border-bottom: 1px solid #d5cdcd;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .line-col{
    border-bottom: 1px solid red;
  }
  .line-divider{
   border-color: #d5cdcd;
    opacity: 0.3;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .list-data-campaigns{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    padding-left: 11px;
    padding-right: 20px;
  
  }
  .content-data-campaigns{
    //width: 33%;
    flex-basis: min-content;
    border: 1px solid #d5cdcd;
    padding: 10px;
  }
  .style-data-campaign{
    text-align: left;
  }
  //css tag visualización data
  .space-tag{
      margin-bottom: 3px !important;
  }
  //css Modal Columns view
  .style-modal-columm{
    .ant-modal-header{
      background: #1890ff !important;
    }
    .ant-modal-title{
      color:#ffffff !important;
    }
    .ant-modal-close{
      color:#ffffff !important;
    }
    h2{
      margin-bottom: 11px;
    }
  }
 
`;

export default GlobalStyles;
